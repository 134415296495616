import React from "react";

/**
 * Returns a essentially blank page. Serves to handle the postSlug.
 * @returns 
 */
function LearningModules() {
  return (
    <div >
      <div >
        <h1 className="text-center mt-5">Empty Page. Go back</h1>
      </div>
    </div>
  );
}

export default LearningModules;