import React from "react";

/**
 * Returns a essentially blank page. Serves to handle the postSlug.
 * @returns 
 */
function EditQuestionSlug() {
  return (
    <div >
      <div >
        <h1 className="text-center mt-5">Custom Learning Module page?</h1>
      </div>
    </div>
  );
}

export default EditQuestionSlug;