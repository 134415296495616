import React from "react";

/**
 * Returns a essentially blank page. Serves to handle the postSlug.
 * @returns 
 */
function QuizModules() {
  return (
    <div className="home">
      <div className="container">

      </div>
    </div>
  );
}

export default QuizModules;